<template>
  <div class="nav">
    <v-navigation-drawer app v-model="drawer" fixed temporary>
      <v-list nav dense>
        <v-list-item-group>
          <router-link
            exact
            active-class="deep-purple--text text--accent-4"
            to="/"
          >
            <v-list-item>
              <v-list-item-title> {{ $t("tabs.home") }} </v-list-item-title>
            </v-list-item></router-link
          >

          <router-link
            exact
            active-class="deep-purple--text text--accent-4"
            to="/download"
          >
            <v-list-item>
              <v-list-item-title> {{ $t("tabs.download") }} </v-list-item-title>
            </v-list-item></router-link
          >

          <router-link
            exact
            active-class="deep-purple--text text--accent-4"
            to="/support"
          >
            <v-list-item>
              <v-list-item-title> {{ $t("tabs.support") }} </v-list-item-title>
            </v-list-item></router-link
          >

          <router-link
            exact
            active-class="deep-purple--text text--accent-4"
            to="/login"
          >
            <v-list-item>
              <v-list-item-title> WEB </v-list-item-title>
            </v-list-item></router-link
          >

          <v-list-group class="drawer-list" no-action sub-group>
            <template v-slot:activator>
              <v-list-item-content class="pl-0">
                <v-list-item-title>Languages</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="(lang, i) in langs"
              :key="i"
              link
              @click="changeLang(lang)"
            >
              <v-list-item-title>{{ lang.title }}</v-list-item-title>
            </v-list-item>
          </v-list-group>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      elevation="0"
      fixed
      color="white"
      light
      width="100%"
      height="72"
    >
      <v-app-bar-nav-icon
        v-if="!$vuetify.breakpoint.mdAndUp"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <template v-if="$vuetify.breakpoint.mdAndUp">
        <div>
          <router-link
            to="/"
            exact
            active-class="active-link"
            class="nav-link"
            >{{ $t("tabs.home") }}</router-link
          >
          <router-link
            to="/download"
            exact
            active-class="active-link"
            class="nav-link"
          >
            {{ $t("tabs.download") }}</router-link
          >
          <router-link
            to="/support"
            exact
            active-class="active-link"
            class="nav-link"
          >
            {{ $t("tabs.support") }}</router-link
          >
        </div>
        <div class="lang d-flex align-center justify-center">
          <v-menu
            content-class="elevation-1"
            bottom
            left
            offset-y
            nudge-bottom="20"
            :elevation="0"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="lang-btn"
                :ripple="false"
                light
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon size="16">mdi-web</v-icon> {{ langLabel }}
              </v-btn>
            </template>

            <v-list class="lang-list">
              <v-list-item
                @click="changeLang(lang)"
                class="lang-item"
                link
                v-for="(lang, i) in langs"
                :key="i"
              >
                <v-list-item-title class="lang-title">{{
                  lang.title
                }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn
            to="/login"
            exact
            elevation="0"
            class="btn-main header-btn w-121 h-49"
            >WEB</v-btn
          >
        </div>
      </template>
    </v-app-bar>
  </div>
</template>

<script>
import i18n from "@/i18n";

export default {
  data() {
    return {
      drawer: false,
      group: null,
      langs: [
        { code: "en", title: "English" },
        { code: "ru", title: "Русский" },
        { code: "az", title: "Azərbaycanca" },
      ],
      langLabel: localStorage.getItem("lang") || "EN",
    };
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
  methods: {
    changeLang(lang) {
      this.drawer = false;
      localStorage.setItem("lang", lang.code);
      i18n.locale = lang.code;
      this.langLabel = lang.code.toUpperCase();
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep.v-menu__content {
  border-radius: 10px !important;
  width: 150px;
}
::v-deep .v-toolbar__content {
  justify-content: space-between;
  max-width: 1440px;
  margin: 0 auto;
}

.header-btn {
  border: 1px solid #7566fe !important;
  &:hover {
    background: #fff !important;
    color: #36324a !important;
  }
}

.nav {
  .active-link {
    border-bottom: 5px solid #7566fd;
  }
  &-link {
    margin-right: 75px;
    padding-bottom: 21px;
    border-radius: 0 0 -25px -25px;
    color: #36324a !important;
    transition: 0.1s ease all;

    &:hover {
      border-bottom: 5px solid #7566fd;
    }
  }
}

.lang {
  &-btn {
    margin-right: 96px;
    color: #36324a !important;
    &::before {
      display: none;
    }
  }
  &-list {
    box-shadow: 0px 3px 6px #00000029 !important;
  }
  &-item {
    min-height: 28px !important;
  }
  &-title {
    font-size: 16px !important;
  }
}

::v-deep.drawer-list {
  & .v-list-item--link {
    padding-left: 48px !important;
  }
  & .v-list-group__header {
    padding-left: 8px !important;
  }
}
</style>
