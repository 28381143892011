<template>
  <div class="footer">
    <v-container class="footer-container">
      <v-row class="px-4">
        <v-col class="d-flex justify-end">
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12" md="4" class="mb-2 mb-md-0">
                  <div class="d-flex flex-column">
                    <h5 class="footer-nav-header font-16 mb-3 mb-md-6">
                      {{ $t("footer.company") }}
                    </h5>
                    <router-link class="mb-2 mb-md-7 white--text" to="/faq">
                      {{ $t("footer.faq") }}</router-link
                    >
                    <router-link
                      class="mb-2 mb-md-7 white--text"
                      to="/privacy-policy"
                    >
                      {{ $t("footer.privacyPolicy") }}</router-link
                    >
                    <router-link
                      class="mb-2 mb-md-7 white--text" 
                      to="/user-terms"
                    >
                      {{ $t("footer.userTerms") }}</router-link
                    >
                    <router-link class="white--text" to="/contact">
                      {{ $t("footer.contactUs") }}</router-link
                    >
                  </div>
                </v-col>
                <v-col cols="12" md="4" class="mb-2 mb-md-0">
                  <div class="d-flex flex-column">
                    <h5 class="footer-nav-header font-16 mb-3 mb-md-6">
                      {{ $t("footer.download") }}
                    </h5>
                    <router-link
                      class="mb-2 mb-md-7 white--text"
                      :to="{ name: 'download.android' }"
                    >
                      {{ $t("footer.android") }}</router-link
                    >
                  </div>
                </v-col>
                <v-col cols="12" md="4" class="">
                  <div class="d-flex flex-column">
                    <h5 class="footer-nav-header font-16 mb-3 mb-md-6">
                      {{ $t("footer.helpCenter") }}
                    </h5>
                    <router-link class="mb-2 mb-md-7 white--text" to="/support">
                      {{ $t("footer.support") }}</router-link
                    >
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="mt-4 mt-md-0">
          <p class="footer-copy mb-0">
            {{ new Date().getFullYear() }} © Feedy App
          </p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.footer {
  background-color: #273443;
  color: #fff;

  &-container {
    padding: 48px 0;
  }
}
</style>
