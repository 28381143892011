<template>
  <v-app app id="app">
    <div v-if="$route.meta === 'login'">
      <router-view />
    </div>
    <div v-else>
      <AppHeader />
      <v-main>
        <router-view />
      </v-main>
      <AppFooter />
    </div>
  </v-app>
</template>

<script>
import AppFooter from "./components/layout/AppFooter.vue";
import AppHeader from "./components/layout/AppHeader.vue";

export default {
  components: { AppHeader, AppFooter },
};
</script>

<style lang="scss">
.container {
  max-width: 1440px !important;
  padding: 0 16px;
}

.theme--light.v-application {
  color: #333 !important;
}
</style>
