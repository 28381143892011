import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/Home/index.vue"),
  },
  {
    path: "/download",
    component: () =>
      import(/* webpackChunkName: "download" */ "../views/Download/index.vue"),
    children: [
      {
        path: "/",
        name: "download.main",
        component: () =>
          import(/* webpackChunkName: "main" */ "../views/Download/Main.vue"),
      },
      {
        path: "android",
        name: "download.android",
        component: () =>
          import(
            /* webpackChunkName: "android" */ "../views/Download/Android.vue"
          ),
      },
      {
        path: "ios",
        name: "download.ios",
        component: () =>
          import(/* webpackChunkName: "ios" */ "../views/Download/IOS.vue"),
      },
    ],
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: () =>
      import(
        /* webpackChunkName: "privacy-policy" */ "../views/PrivacyPolicy/index.vue"
      ),
  },
  {
    path: "/faq",
    name: "faq",
    component: () =>
      import(/* webpackChunkName: "faq" */ "../views/FAQ/index.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/Contact/index.vue"),
  },
  {
    path: "/login",
    name: "login",
    meta: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login/index.vue"),
  },
  {
    path: "/user-terms",
    name: "user-terms",
    component: () =>
      import(
        /* webpackChunkName: "user-terms" */ "../views/UserTerms/index.vue"
      ),
  },
  {
    path: "/support",
    name: "support",
    component: () =>
      import(/* webpackChunkName: "support" */ "../views/Support/index.vue"),
    children: [
      {
        path: "getting-started",
        name: "getting-started",
        component: () =>
          import(
            /* webpackChunkName: "getting-started" */ "../views/Support/GettingStarted/index.vue"
          ),
      },
      {
        path: "features",
        name: "features",
        component: () =>
          import(
            /* webpackChunkName: "features" */ "../views/Support/Features/index.vue"
          ),
      },
      {
        path: "personal-data",
        name: "personal-data",
        component: () =>
          import(
            /* webpackChunkName: "personal-data" */ "../views/Support/PersonalData/index.vue"
          ),
      },
    ],
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
