import Vue from "vue";
import Vuex from "vuex";

import SearchParams from "./modules/searchParams";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: { SearchParams },
});
